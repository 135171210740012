<template>
  <v-container fluid>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <v-col class="text-right">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="createItem"
      >
        <v-icon>mdi-account-plus</v-icon>
        {{ $t("create") }}
      </v-btn>
    </v-col>
    <DocumentDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveFileEvent"
    ></DocumentDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('document.list')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon large class="mr-2" v-on="on" @click="editItem(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon large class="mr-2" v-on="on" @click="deleteFile(item.id)"
                >mdi-delete</v-icon
              >
            </template>
            <span>{{ $t("delete") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import DocumentDialog from "../dialogs/DocumentDialog"
import BaseTable from "./BaseTable.vue";
export default {
  data: (vm) => ({
    url: "/document/document/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
        width: "10%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("name"),
        value: "name",
        width: "10%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("document.size"),
        value: "size",
        width: "10%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
        width: "5%"
      }
    ],
    items: [],
    options: {},
    totalItems: 0,
    loading: false,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [20, 50, 100],
    },
  }),
  mixins: [BaseTable],
  computed: {},
  components: {
    DocumentDialog,
    CommonAlertDialog
  },
  methods: {
    // setResult(data) {
    //   this.totalItems = data.length;
    //   this.items = data;
    // },
    parseJsonField(item) {
      console
        .log(item)(item)
        .forEach((element) => {
          console.log(element);
        });
    },
    deleteFile(id) {
      const url = "/document/document/delete_file/"
      this.postApi({id:id},url)
    },
  },
  mounted() {},
};
</script>
