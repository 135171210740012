<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="item.name"
                :label="$t('document.document')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!-- <v-text-field
                v-model="item.issue_time"
                :label="$t('issue-time')"
              ></v-text-field> -->
              <v-file-input
                v-model="item.file"
                show-size
              ></v-file-input>
            </v-col>
            <!-- <v-col>
              <v-text-field
                v-model="item.expired_time"
                :label="$t('expired-time')"
              ></v-text-field>
            </v-col> -->
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="disableSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { DatePicker } from "v-calendar";
import BaseDialog from "./BaseDialog";
export default {
  props: {},
  data: (vm) => ({
    topic: vm.$i18n.t("document.document"),
    // item: {
    //     enable_archive: false,
    // },
  }),
  components: {
    DatePicker,
  },
  mixins: [BaseDialog],
  methods: {
    save() {
      console.log("doc save")
      console.log("dialog save");
      let editedItem = this._.cloneDeep(this.item);
      console.log(editedItem);
      this.$emit("onSave", editedItem);
    },
  },
};
</script>
